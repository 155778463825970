import React from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
const cardImageStyle = {
    height: '44px',
    width: '44px'
}
const descriptionTextStyle = {
    textAlign: 'justify'
}
const ServicesComponent = ({ bgColor, page, data }) => {
    return (
        <Container fluid className="pb-5 pt-2" style={{ background: bgColor }}>
            <Container>
                {page === 'aboutus' && <Row>
                    <Col className="services-section-right">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="subheading">Who We Are</h6>
                            </div>
                        </div>
                        <h2 className="sub-subheading">Why choose us?</h2>
                    </Col>
                </Row>}
                {
                    data.map((serviceRow, rowIndex) => {
                        return (
                            <Row key={`row_${rowIndex}`} className={(rowIndex === 1 && 'mt-3') || 'pt-4'}>
                                {serviceRow.map((service,serviceNo) => (
                                    <Col key={`col_${serviceNo}`} md="4" sm="12" xs="12" className="mt-2">
                                        <Card style={{ border: '0px' }} className={rowIndex === 1 && serviceNo === 1 && page === 'aboutus' && 'last-card'}>
                                            <Card.Body>
                                                <Row>
                                                    <Col md="4" className="pb-2">
                                                        <Card.Img style={cardImageStyle} height="44px" width="44px" variant="top" src={service.imageSrc} />
                                                    </Col>
                                                </Row>
                                                <Card.Title className="font-weight-bold">{service.heading}</Card.Title>
                                                <Card.Text style={descriptionTextStyle}>{service.description}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )
                    })
                }

            </Container>
        </Container>
    );
};

export default ServicesComponent;