import React from 'react'
import { Col, Row, Container, Button } from 'react-bootstrap'
import CustomButton from '../elements/CustomButton'

const NeedProjectComponent = (
    { backgroundColor,
        className,
        page,
        subHeading,
        heading,
        submitSubscriptionForm,
        handleSubscribeEmail,
        subscribeEmail }) => {
    return (
        <Container fluid className={className} style={{ background: backgroundColor }}>
            <Container>
                <Row>
                    <Col className="carousel-columns">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="line"></div>
                            <div>
                                <h6 className="carousel-section-subheading">{subHeading}</h6>
                            </div>
                        </div>
                    </Col>
                    {page === 'services' && <Col className="carousel-columns">
                        <div className="d-flex justify-content-end align-items-center">
                            <div>
                                <h6 className="carousel-section-subheading text-right">Or call us now</h6>
                            </div>
                        </div>

                    </Col>}
                </Row>
                <Row>
                    <Col>
                        <h1 className="text-uppercase text-nowrap font-weight-bold sub-subheading need-project">{heading}</h1>
                    </Col>
                    {page === 'services' && <Col>
                        <h6 className="font-weight-bold text-right sub-subheading need-project" style={{ fontSize: '30px', letterSpacing: '1.5px' }}>
                            (123) 456 7890
                    </h6>
                    </Col>}
                    {page === 'aboutus' && <Col className="text-right">
                        <CustomButton
                            buttonTitle="Get a Free Quote "
                            reference='/contactus/'
                            lineColor="line-standard"
                            style={{ marginTop: '7%' }}
                            className="btn btn-outline btn-standard-outline d-none d-md-inline-block d-lg-inline-block" />
                    </Col>}
                    {(page === 'blogs' || page === 'contactus') && <Col className="text-right pl-5 subscribe-button" xs="12" md="6">

                        <div className="input-group mb-3">
                            <input
                                type="email"
                                className="form-control input-subscribe"
                                placeholder="Enter Email"
                                aria-label="Enter Email"
                                value={subscribeEmail}
                                onChange={(event) => handleSubscribeEmail(event.target.value)}
                                aria-describedby="basic-addon2" />
                            <div className="input-group-append">
                                <Button
                                    className="btn-subscribe"
                                    disabled={subscribeEmail === ''}
                                    onClick={submitSubscriptionForm}>
                                    Subscribe
                                    <div className="line-white"></div>
                                </Button>
                            </div>
                        </div>
                    </Col>}
                </Row>
                <Row className="d-md-none d-lg-none d-xl-none">
                {page === 'aboutus' && <Col>
                        <CustomButton
                            buttonTitle="Get a Free Quote "
                            reference='/contactus/'
                            lineColor="line-standard"
                            style={{ marginTop: '7%' }}
                            className="btn btn-outline btn-standard-outline" />
                    </Col>}
                </Row>
            </Container>
        </Container>
    )
}

export default NeedProjectComponent