import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import BusinessAdvisoryContainer from "../containers/BusinessAdvisoryContainer"

const BusinessAdvisory = (props) => (
  <Layout 
  pageInfo={{ pageName: "Business Advisory" }} 
  styleClass="our-story-page" 
  location={'/bussinessadvisory/'}>
    <SEO title="Business Advisory" />
    <BusinessAdvisoryContainer />
  </Layout>
)

export default BusinessAdvisory
