import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage';
import banner from '../images/slicing/service/banner.png'
import WorkStrategy from '../components/services/WorkStrategy';
import ServicesComponent from '../components/services/ServicesComponent';
import CoreValueComponent from '../components/services/CoreValueComponent';
import CaseStudyComponent from '../components/services/CaseStudyComponent';
import TestimonialComponent from '../components/services/TestimonialComponent';
import NeedProjectComponent from '../components/services/NeedProjectComponent';
import {servicePageCardData} from '../staticdata/homepageServicesData'
class BusinessAdvisoryContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Business Advisory"
                    bannerImage={banner} />
                <WorkStrategy />
                <ServicesComponent bgColor='#ffffff' page="services" data={servicePageCardData} />
                <CoreValueComponent/>
                <CaseStudyComponent/>
                <TestimonialComponent/>
                <NeedProjectComponent 
                className="mt-5" 
                page="services"
                subHeading="Need a successful project?"
                heading="Need a successful project?"/>
            </>
        );
    }
}

export default BusinessAdvisoryContainer;